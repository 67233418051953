/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'collection-play-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M2.5 3.5a.5.5 0 010-1h11a.5.5 0 010 1zm2-2a.5.5 0 010-1h7a.5.5 0 010 1zM0 13a1.5 1.5 0 001.5 1.5h13A1.5 1.5 0 0016 13V6a1.5 1.5 0 00-1.5-1.5h-13A1.5 1.5 0 000 6zm6.258-6.437a.5.5 0 01.507.013l4 2.5a.5.5 0 010 .848l-4 2.5A.5.5 0 016 12V7a.5.5 0 01.258-.437"/>',
    },
});
